<mat-card>
  <mat-form-field class="example-full-width">
    <mat-label>Produkt Name</mat-label>
    <input matInput [(ngModel)]="productName">
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <mat-label>Beschreibung</mat-label>
    <input matInput [(ngModel)]="description">
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <mat-label>Zutaten</mat-label>
    <input matInput [(ngModel)]="ingredients">
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <mat-label>Kategorie</mat-label>
    <mat-select [(ngModel)]="category">
      <mat-option *ngFor="let cat of categories" [value]="cat">
        {{cat.categorieName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
  <div class="file-upload">
    <button mat-mini-fab style="background-color: white;" (click)="fileUpload.click()">
      <mat-icon>file_upload</mat-icon>
    </button>
    <span style="padding-left: 7px;">
      {{fileName || "No file uploaded yet."}}
    </span>

  </div>
  <div align="end">
    <button mat-flat-button class="post-button" (click)="onSubmit()" [disabled]="disable_post()">Hinzufügen</button>
  </div>
</mat-card>