import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-product-post',
  templateUrl: './product-post.component.html',
  styleUrls: ['./product-post.component.css']
})
export class ProductPostComponent implements OnInit {
  productName: string = "";
  description: string = "";
  ingredients: string = "";
  category: any;
  categories: any[] = [];
  productFormData: FormData = new FormData();;
  fileName: string = "";
  serverURL = environment.server_URL;

  constructor(
    private http: HttpClient, private dialogRef: MatDialogRef<ProductPostComponent>
  ) { }

  ngOnInit(): void {
    this.loadCategories();
  }

  loadCategories(): void {
    this.http.get(environment.server_URL + "categories/").subscribe((data: any) => {
      this.categories = data;
      console.log(this.categories)
    },
      error => {
        console.error('Error loading categories', error);
      }
    );
  }



  onSubmit(): void {
    let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,PUT,DELETE'
    });

    this.productFormData.append('productName', this.productName);
    this.productFormData.append('description', this.description);
    this.productFormData.append('ingredients', this.ingredients);
    this.productFormData.append('categorieId', this.category._id);

    this.http.post(this.serverURL + 'products/', this.productFormData)
      .subscribe((data: any) => {
        console.log(data);
        this.clearAll();
        this.dialogRef.close('posted');

        // this.dialog.open(SuccessOrderComponent, {
        //   data: { html_text: "Ihre Bestellung war erfolgreich!", },
        // });
      },
        (error) => {                              //Error callback
          console.error('error caught in component: ' + error);
          alert('Produkt konnte nicht hinzugefügt werden.');
          this.clearAll();
        })
  }

  disable_post() {
    return !this.productName || !this.description || !this.category || !this.fileName || !this.ingredients
  }

  clearAll() {
    this.productName = "";
    this.description = "";
    this.ingredients = "";
    this.category = null;
    this.fileName = "";
    this.productFormData = new FormData();
  }

  onFileSelected(event) {

    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;

      this.productFormData.append('productImage', file);
    }
  }

}