import { Component, OnInit } from '@angular/core';
import { ChartService } from '../chart.service';
import { Router } from '@angular/router';
import { trigger, keyframes, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('bounceInAnimation', [
      transition(':enter', [
        animate('0.5s', keyframes([
          style({ opacity: 0, transform: 'scale(.3)', offset: 0 }),
          style({ opacity: 1, transform: 'scale(1.05)', offset: 0.5 }),
          style({ transform: 'scale(.9)', offset: 0.7 }),
          style({ transform: 'scale(1)', offset: 1 })
        ]))
      ])
    ])
  ]
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, private chartService: ChartService) { }

  ngOnInit(): void {
  }

  navigateToCategories(): void {
    const navigationExtras = {
      state: {
        animate: true // Indicate that animation is required
      }
    };
    this.router.navigate(['/categories'], navigationExtras);
  }

  addUser(firstname: string, lastname: string) {
    this.chartService.addUserName(firstname, lastname);
    this.chartService.logIn();
    this.navigateToCategories();
  }

  addTable(table: number) {
    this.chartService.setTable(table);
    this.chartService.logIn();
    this.navigateToCategories();
  }

  disable_login(firstname, lastname) {
    return !firstname || !lastname
  }

  disable_table(table) {
    return !table
  }
}