import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  private _logged = false;
  private _user = {
    firstname: '',
    lastname: '',
  };
  private _products = [];
  private _table = 0;

  get user(): any {
    return this._user;
  }

  get logged(): any {
    return this._logged;
  }

  get products(): any {
    return this._products;
  }

  get table(): any {
    return this._table;
  }

  addUserName(fn: string, ln: string) {
    this._user.firstname = fn;
    this._user.lastname = ln;
  }

  logIn() {
    this._logged = true;
  }

  addProductToList(product) {
    product = { ...product, isLoading: false };
    this._products.push(product);
  }

  removeFromProduct(product) {
    this._products.splice(product, 1);
  }

  clearProducts() {
    this._products = [];
  }

  setTable(table: number) {
    this._table = table;
  }
}