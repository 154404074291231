<mat-drawer-container class="example-container" autosize>
  <mat-toolbar class="fixed-toolbar" *ngIf="showToolbar">
    <button mat-icon-button (click)="drawer.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="spacer" style="color: #553A51;">#FICUS-Order</span>
    <button mat-icon-button (click)="openBasket()" *ngIf="chartService.logged">
      <mat-icon>shopping_basket</mat-icon>
      <mat-badge *ngIf="chartService.products.length > 0" [matBadge]="chartService.products.length"
        matBadgeColor="warn"></mat-badge>
    </button>
  </mat-toolbar>
  <mat-drawer #drawer opened="false" mode="push" position="start" class="example-sidenav">
    <mat-nav-list>
      <mat-list-item (click)="openLogin()" *ngIf="!chartService.logged">
        <mat-icon matListItemIcon>login</mat-icon>
        <div matListItemTitle>Anmelden</div>
      </mat-list-item>
      <mat-list-item (click)="openCategories()" *ngIf="chartService.logged">
        <mat-icon matListItemIcon>room_service</mat-icon>
        <div matListItemTitle>Bestellen</div>
      </mat-list-item>
      <!-- <mat-list-item (click)="openRecipes()">
        <mat-icon matListItemIcon>insert_drive_file</mat-icon>
        <div matListItemTitle>Rezepte</div>
      </mat-list-item> -->
      <mat-list-item>
        <a href="https://ficus-coding.de/">© 2024 FICUS Coding</a>
      </mat-list-item>
    </mat-nav-list>
  </mat-drawer>
  <div [ngClass]="{'content': showToolbar}">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>