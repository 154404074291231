<body>
  <div class="search-filter-container">
    <mat-form-field class="search-field">
      <mat-label>Suche</mat-label>
      <input matInput (input)="applySearchFilter($event.target.value)">
    </mat-form-field>

    <mat-form-field class="filter-field">
      <mat-label>Bestelldatum</mat-label>
      <input matInput [matDatepicker]="picker" (dateChange)="applyDateFilter($event.value)"
        placeholder="Datum auswählen" [value]="selectedDate">
      <button mat-icon-button matSuffix *ngIf="selectedDate" (click)="clearDateFilter()">
        <mat-icon>close</mat-icon>
      </button>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="status-field">
      <mat-label>Status</mat-label>
      <mat-select (selectionChange)="applyStatusFilter($event.value)" [value]="selectedStatus">
        <mat-option value="all">Alle</mat-option>
        <mat-option value="done">Erledigt</mat-option>
        <mat-option value="pending">Wartet</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-card *ngFor="let order of filteredOrders" class="order-card">
    <div class="header-content">
      <span class="header-left">
        <!-- <mat-card-title>{{ order.firstname }} {{ order.lastname }}</mat-card-title> -->
        <mat-card-title>Tisch {{ order.tableNumber }}</mat-card-title>
        <mat-card-subtitle>{{ order.timestamp | date: 'dd.MM.yyyy, HH:mm' }} Uhr</mat-card-subtitle>
      </span>
      <div class="header-right">
        <mat-icon mat-card-icon *ngIf="order.status=='done'" style="color: #A8BE58;">check_circle</mat-icon>
        <button mat-icon-button *ngIf="order.status=='pending'">
          <mat-icon (click)="setDone(order._id)" style="color: #E1D894;">hourglass_empty</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <div *ngFor="let product of order.products; let i = index" class="product-container">
        <div>{{i+1}}</div>
        <img mat-card-md-image [src]="serverURL + product.product_id.productImage" class="thumb" width="50">
        <span>{{ product.product_id.productName }}</span>
      </div>
    </mat-card-content>
  </mat-card>
</body>