import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ChartService } from './chart.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BasketComponent } from './basket/basket.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('drawer') drawer: MatDrawer;

  title = 'ficus-order';
  showToolbar: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private router: Router, public chartService: ChartService, private dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.showToolbar = !this.activatedRoute.snapshot.firstChild?.data.hideToolbar;
    });
  }

  openBasket() {
    const dialogRef = this.dialog.open(BasketComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == 'ordered') {
        this._snackBar.open('Bestellung abegeben!', 'OK');
        this.chartService.clearProducts();
      }
    });
  }

  openRecipes() {
    this.drawer.toggle();
    this.router.navigate(['/recipes']);
  }

  openCategories() {
    this.drawer.toggle();
    this.router.navigate(['/categories']);
  }

  openLogin() {
    this.drawer.toggle();
    this.router.navigate(['']);
  }
}
