<h2 mat-dialog-title style="width: 260px; text-align: right;">السلام عليكم ورحمة الله وبركاته</h2>
<mat-dialog-content class="mat-typography">
  <!-- <h1>{{chartService.user.firstname}} {{chartService.user.lastname}},</h1> -->
  <h1>Tisch {{chartService.table}},</h1>
  <p *ngIf="chartService.products.length">hier ist deine Auswahl:</p>
  <p *ngIf="!chartService.products.length">dein Sepet ist leer!</p>
  <div class="item" *ngFor="let product of chartService.products, index as i">
    <img [src]="serverURL + product.productImage" width="110" height="110">
    <div>
      <table id="container">
        <tr>
          <td valign="top">
            <div id="main">{{product.productName}}</div>
          </td>
        </tr>
        <tr>
          <td valign="bottom" style="text-align: right;">
            <button mat-icon-button class="delete-button" (click)="removeProduct(i, product)">
              <mat-icon *ngIf="!product.isLoading; else spinnerTemplate" inline>delete</mat-icon>
              <ng-template #spinnerTemplate>
                <span class="spinner-container" (click)="$event.stopPropagation()">
                  <mat-spinner diameter="23"></mat-spinner>
                </span>
              </ng-template>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close color="warn">Zurück</button>
  <button mat-button cdkFocusInitial style="background-color: #A8BE58; color: #553A51;" (click)="postOrder()"
    [disabled]="!chartService.products.length"><b>Bestellen</b></button>
</mat-dialog-actions>