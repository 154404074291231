<body>
  <div class="search-filter-container">
    <mat-form-field class="search-field">
      <mat-label>Suche</mat-label>
      <input matInput (input)="applySearchFilter($event.target.value)">
    </mat-form-field>

    <mat-form-field class="status-field">
      <mat-label>Status</mat-label>
      <mat-select (selectionChange)="applySockFilter($event.value)" [value]="selectedStock">
        <mat-option value="all">Alle</mat-option>
        <mat-option value="inStock">Vorrätig</mat-option>
        <mat-option value="outOfStock">Nicht im Vorrat</mat-option>
        <mat-option value="disabled">Deaktiviert</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="add-field">
      <button mat-flat-button class="post-button" (click)="postProduct()" style="width: 100%;">Hinzufügen</button>
    </div>
  </div>

  <mat-card *ngFor="let product of filteredProducts" class="product-card">
    <div class="header-content">
      <span class="header-left">
        <mat-card-title>{{ product.productName }}</mat-card-title>
      </span>
      <div class="header-right">
        <button mat-icon-button>
          <mat-icon (click)="deleteProduct(product._id)">delete</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <div class="product-container">
        <img mat-card-md-image [src]="serverURL + product.productImage" class="thumb" width="50">
        <div style="width: 100%;">
          {{ product.categorie.categorieName }}
          <div>
            <mat-select (selectionChange)="setStock(product._id, $event.value)" [value]="product.stock">
              <mat-option value="inStock">Vorrätig</mat-option>
              <mat-option value="outOfStock">Nicht im Vorrat</mat-option>
              <mat-option value="disabled">Deaktiviert</mat-option>
            </mat-select>
          </div>
        </div>

      </div>
    </mat-card-content>
  </mat-card>
</body>