import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, keyframes, style, animate, transition } from '@angular/animations';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
  animations: [
    trigger('bounceInAnimation', [
      transition(':enter', [
        animate('0.5s', keyframes([
          style({ opacity: 0, transform: 'scale(.3)', offset: 0 }),
          style({ opacity: 1, transform: 'scale(1.05)', offset: 0.5 }),
          style({ transform: 'scale(.9)', offset: 0.7 }),
          style({ transform: 'scale(1)', offset: 1 })
        ]))
      ])
    ])
  ],
})
export class CategoriesComponent implements OnInit {
  categories = [];
  serverURL = environment.server_URL;

  constructor(private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    this.getCategories();
  }

  navigateToCards(categorie_id) {
    const navigationExtras = {
      queryParams: {
        categorie_id: categorie_id,
      }
    };
    this.router.navigate(['/cards'], navigationExtras);
  }

  getCategories() {
    this.http.get(environment.server_URL + "categories/").subscribe((data: any) => {
      this.categories = data;
      console.log(this.categories);
    },
      (error) => {                              //Error callback
        console.error('error caught in component: ' + error)
        alert('Categorien konnten nicht geladen werden! Eine Bestellung ist leider nicht möglich.')
        //throw error;   //You can also throw the error to a global error handler
      })
  }
}
