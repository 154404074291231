import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { ChartService } from '../chart.service';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-cards-detail',
  templateUrl: './cards-detail.component.html',
  styleUrls: ['./cards-detail.component.css'],
  animations: [
    trigger('popupAnimation', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'scale(1)', opacity: 1 })),
      ])
    ]),
    trigger('rotate180', [
      state('normal', style({
        transform: 'rotate(0deg)'
      })),
      state('flipped', style({
        transform: 'rotate(180deg)'
      })),
      transition('normal => flipped', [
        animate('150ms ease-out')
      ]),
      transition('flipped => normal', [
        animate('150ms ease-in')
      ])
    ]),
    trigger('slideDown', [
      transition(':enter', [
        style({ transform: 'translateY(-5%)', opacity: 0 }),
        animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        animate('150ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ transform: 'translateY(-5%)', opacity: 0 })),
      ]),
    ]),
    trigger('starAnimation', [
      state('inactive', style({
        color: 'rgb(151, 151, 151)',
        transform: 'scale(1)'
      })),
      state('active', style({
        color: 'gold',
        transform: 'scale(1.1)'
      })),
      transition('inactive <=> active', animate('200ms ease-in-out'))
    ]),
    trigger('fadeFromRightToLeft', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate('50ms', style({ opacity: 1, transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('50ms', style({ opacity: 0, transform: 'translateX(100%)' }))
      ])
    ]),
  ],
})
export class CardsDetailComponent implements OnInit {
  @ViewChild('message') messageInput: ElementRef;

  panelOpenState: boolean = false;
  flipState: string = 'normal';
  rating: number = 0;
  starStates: string[] = ['inactive', 'inactive', 'inactive', 'inactive', 'inactive'];
  product: any;
  serverURL = environment.server_URL;
  stars: number[] = [1, 2, 3, 4, 5];
  all_rates: number = 0;
  isLoading = false;

  constructor(private route: ActivatedRoute, private location: Location, private chartService: ChartService, private http: HttpClient) {

  }

  ngOnInit(): void {
    this.getProduct(this.route.snapshot.queryParamMap.get('product_id'));
  }

  goBack(): void {
    this.location.back();
  }

  togglePanel() {
    this.flipState = this.flipState === 'normal' ? 'flipped' : 'normal';
    this.panelOpenState = !this.panelOpenState
  }

  rate(value: number) {
    this.rating = value;
    this.starStates = this.starStates.map((state, index) => index < value ? 'active' : 'inactive');
  }

  getProduct(product_id) {
    this.http.get(environment.server_URL + "products/" + product_id).subscribe((data: any) => {
      this.product = data;
      console.log(this.product);
      for (let i = 0; i < this.product.reviews.length; i++) {
        this.all_rates += this.product.reviews[i].rate;
      }
      this.all_rates = this.all_rates / this.product.reviews.length;

      console.log(this.all_rates)
    },
      (error) => {                              //Error callback
        console.error('error caught in component: ' + error)
        alert('Categorien konnten nicht geladen werden! Eine Bestellung ist leider nicht möglich.')
        //throw error;   //You can also throw the error to a global error handler
      })
  }

  addReview(text_input) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,PUT,DELETE'
    });

    let review_obj = {
      firstname: this.chartService.user.firstname,
      lastname: this.chartService.user.lastname,
      review_text: text_input,
      rate: this.rating
    }

    this.http.patch(environment.server_URL + "products/review/" + this.product._id, review_obj, { headers })
      .subscribe((data: any) => {
        console.log(data);
        this.rate(0);
        this.messageInput.nativeElement.value = "";
        // this.dialog.open(SuccessOrderComponent, {
        //   data: { html_text: "Ihre Bewertung wurde hinzugefügt!", },
        // });
        this.getProduct(this.product._id);
      },
        (error) => {                              //Error callback
          console.error('error caught in component: ' + error)
          alert('Bestellung war NICHT erfolgreich! Bitte versuchen Sie es später nocheinmal.')
          //throw error;   //You can also throw the error to a global error handler
        })
  }

  addProduct() {
    this.isLoading = true;
    // Simulate a 0.5 second delay
    setTimeout(() => {
      let product_to_order = {
        product_id: this.product._id,
        productName: this.product.productName,
        productImage: this.product.productImage
      }

      this.chartService.addProductToList(product_to_order);
      console.log(this.chartService.products);

      this.isLoading = false;
    }, 750);

  }
}
