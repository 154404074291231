import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CategoriePostComponent } from '../categorie-post/categorie-post.component';

@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.css']
})
export class AllCategoriesComponent implements OnInit {

  categories = [];
  filteredCategories = [];
  serverURL = environment.server_URL;

  constructor(private http: HttpClient, private dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories() {
    this.http.get(environment.server_URL + "categories/").subscribe((data: any) => {
      this.categories = data;
      this.filteredCategories = this.categories;
      console.log(this.categories);
    },
      (error) => {                              //Error callback
        console.error('error caught in component: ' + error)
        //throw error;   //You can also throw the error to a global error handler
      })
  }

  applySearchFilter(searchValue: string) {
    this.filteredCategories = this.categories.filter(categorie =>
      categorie.categorieName.toLowerCase().includes(searchValue.toLowerCase()) ||
      categorie.description.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  deleteCategorie(id: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,PUT,DELETE'
    });

    this.http.delete(environment.server_URL + 'categories/' + id, { headers })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.getCategories();
        },
        err => {
          console.log(err);
        });
  }

  postCategorie() {
    const dialogRef = this.dialog.open(CategoriePostComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == 'posted') {
        this._snackBar.open('Kategorie erstellt!', undefined, {
          duration: 3000,
        });
        this.getCategories();
      }
    });
  }

}
