import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  orders = [];
  filteredOrders = [];
  selectedDate: Date | null = null;
  selectedStatus = 'all';
  serverURL = environment.server_URL;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getOrders();
  }

  getOrders() {
    this.http.get(environment.server_URL + "orders/").subscribe((data: any) => {
      this.orders = data;
      this.filteredOrders = this.orders;
      console.log(this.orders);
    },
      (error) => {                              //Error callback
        console.error('error caught in component: ' + error)
        //throw error;   //You can also throw the error to a global error handler
      })
  }

  applySearchFilter(searchValue: string) {
    this.filteredOrders = this.orders.filter(order =>
      order.firstname.toLowerCase().includes(searchValue.toLowerCase()) ||
      order.lastname.toLowerCase().includes(searchValue.toLowerCase()) ||
      order.products.some(product => product.product_id.productName.toLowerCase().includes(searchValue.toLowerCase()))
    );
  }

  applyDateFilter(selectedDate: Date) {
    this.selectedDate = selectedDate;
    const startOfDay = new Date(selectedDate);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(selectedDate);
    endOfDay.setHours(23, 59, 59, 999);

    if (selectedDate) {
      this.filteredOrders = this.orders.filter(order => {
        const orderDate = new Date(order.timestamp);
        return orderDate >= startOfDay && orderDate <= endOfDay;
      });
    }
    else {
      this.filteredOrders = this.orders;
    }
  }

  clearDateFilter() {
    this.selectedDate = null;
    this.filteredOrders = this.orders;
  }

  applyStatusFilter(status: string, updateFilteredOrders = true) {
    this.selectedStatus = status;

    if (updateFilteredOrders) {
      this.filteredOrders = this.orders;
    }

    if (status === 'all') {
      return;
    }

    this.filteredOrders = this.filteredOrders.filter(order => order.status === status);
  }

  setDone(id) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,PUT,DELETE'
    });

    this.http.patch(environment.server_URL + "orders/status/" + id, { headers })
      .subscribe((data: any) => {
        console.log(data);
        // this.dialog.open(SuccessOrderComponent, {
        //   data: { html_text: "Ihre Bewertung wurde hinzugefügt!", },
        // });
        this.getOrders();
      },
        (error) => {                              //Error callback
          console.error('error caught in component: ' + error)
          alert('Bestellung war NICHT erfolgreich! Bitte versuchen Sie es später nocheinmal.')
          //throw error;   //You can also throw the error to a global error handler
        })
  }
}
