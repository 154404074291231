<body [@popupAnimation] *ngIf="product">
  <div>
    <!-- Product Details -->
    <mat-card>
      <mat-card-header>
        <div mat-card-avatar><button mat-icon-button class="close-button" (click)="goBack()">
            <mat-icon>close</mat-icon>
          </button></div>
        <mat-card-title>{{product.productName}}</mat-card-title>
      </mat-card-header>
      <img mat-card-image [src]="serverURL + product.productImage">
      <mat-card-content>
        <button mat-button style="background-color: #E1D894; width: 100%; color: #553A51;" (click)="addProduct()">
          <span *ngIf="!isLoading; else spinnerTemplate">
            Hinzufügen
          </span>
          <ng-template #spinnerTemplate>
            <span class="spinner-container" (click)="$event.stopPropagation()">
              <mat-spinner diameter="24"></mat-spinner>
            </span>

          </ng-template>
        </button>
        <p>
          {{product.description}}
        </p>
        <p>
          Zutaten:<br>{{product.ingredients}}
        </p>
      </mat-card-content>
      <mat-card-actions style="display: flex; justify-content: space-between; align-items: center;">
        <span style="display: flex; align-items: center; padding-left: 13px;">
          <mat-icon *ngFor="let star of stars; let i = index"
            [ngClass]="{'active': i &lt; (all_rates | number:'1.0-0'), 'inactive': i >= (all_rates | number:'1.0-0')}"
            inline>star</mat-icon>
          <span style="color: rgb(151, 151, 151); font-size: 13px;">&nbsp;{{all_rates |
            number:'1.1-1'}}&nbsp;({{product.reviews.length}})</span>
        </span>
        <button mat-button (click)="togglePanel()">
          Bewertungen
          <mat-icon [@rotate180]="flipState">keyboard_arrow_down</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
    <!-- Review-Section -->
    <mat-card style="background-color: #5e4059;" *ngIf="panelOpenState && product.reviews.length" [@slideDown]>
      <mat-card-content *ngFor="let review of product.reviews; let ia = index">
        <p style="display: grid;
        grid-template-columns: auto auto;"><span style="font-size: 17px;">{{review.firstname}} {{review.lastname}}<div
              style="font-size: 11px; padding-top: 3px;">
              <mat-icon *ngFor="let star of stars; let i = index"
                [ngClass]="{'active': i &lt; review.rate, 'inactive': i >= review.rate}" inline>star</mat-icon>
            </div>
          </span>
          <span style="text-align: right;color: lightgray; font-size: 11px;">
            {{review.timestamp | date: 'dd.MM.yyyy' : 'GMT+2' }}, {{review.timestamp | date: 'HH:mm' : 'GMT+2' }} Uhr
          </span>
        </p>
        <div [ngClass]="{'review-text': ia &lt; (product.reviews.length-1)}">
          {{review.review_text}}
        </div>
        <mat-divider *ngIf="ia &lt; (product.reviews.length-1)"></mat-divider>
      </mat-card-content>
    </mat-card>
    <!-- Add Review -->
    <mat-card style="background-color: #684762;" *ngIf="panelOpenState" [@slideDown]>
      <mat-card-content>
        <div style="display: flex; align-items: center;">
          <mat-form-field class="example-form">
            <mat-label>Hier bewerten...</mat-label>
            <input matInput #message maxlength="256">
            <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
          </mat-form-field>
          <button mat-icon-button (click)="addReview(message.value)" *ngIf="message.value?.trim()&&rating"
            [@fadeFromRightToLeft]><mat-icon>send</mat-icon></button>
        </div>
        <div>
          <mat-icon *ngFor="let state of starStates; let i = index" (click)="rate(i + 1)"
            [@starAnimation]="state">star</mat-icon>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</body>