<mat-card>
  <mat-form-field class="example-full-width">
    <mat-label>Kategorie Name</mat-label>
    <input matInput [(ngModel)]="categorieName">
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <mat-label>Beschreibung</mat-label>
    <input matInput [(ngModel)]="description">
  </mat-form-field>
  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
  <div class="file-upload">
    <button mat-mini-fab style="background-color: white;" (click)="fileUpload.click()">
      <mat-icon>file_upload</mat-icon>
    </button>
    <span style="padding-left: 7px;">
      {{fileName || "No file uploaded yet."}}
    </span>

  </div>
  <div align="end">
    <button mat-flat-button class="post-button" (click)="onSubmit()" [disabled]="disable_post()">Hinzufügen</button>
  </div>
</mat-card>