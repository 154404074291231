import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ProductPostComponent } from '../product-post/product-post.component';

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.css']
})
export class AllProductsComponent implements OnInit {

  products = [];
  filteredProducts = [];
  selectedStock = 'all';
  serverURL = environment.server_URL;

  constructor(private http: HttpClient, private dialog: MatDialog, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts() {
    this.http.get(environment.server_URL + "products/").subscribe((data: any) => {
      this.products = data;
      this.filteredProducts = this.products;
      console.log(this.products);
    },
      (error) => {                              //Error callback
        console.error('error caught in component: ' + error)
        //throw error;   //You can also throw the error to a global error handler
      })
  }

  applySearchFilter(searchValue: string) {
    this.filteredProducts = this.products.filter(product =>
      product.productName.toLowerCase().includes(searchValue.toLowerCase()) ||
      product.categorie.categorieName.toLowerCase().includes(searchValue.toLowerCase()) ||
      product.ingredients.toLowerCase().includes(searchValue.toLowerCase())
    );
  }

  applySockFilter(stock: string) {
    this.selectedStock = stock;

    this.filteredProducts = this.products;

    if (stock === 'all') {
      return;
    }

    this.filteredProducts = this.filteredProducts.filter(product => product.stock === stock);
  }

  setStock(id, stockInfo) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,PUT,DELETE'
    });

    let body = [{
      "propName": "stock", "value": stockInfo
    }]
    this.http.patch(environment.server_URL + "products/" + id,
      body,
      { headers })
      .subscribe((data: any) => {
        console.log(data);
        // this.dialog.open(SuccessOrderComponent, {
        //   data: { html_text: "Ihre Bewertung wurde hinzugefügt!", },
        // });
        this.getProducts();
      },
        (error) => {                              //Error callback
          console.error('error caught in component: ' + error)
          alert('Fehler! Bitte versuchen Sie es später nocheinmal.')
          //throw error;   //You can also throw the error to a global error handler
        })
  }

  deleteProduct(id: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,PUT,DELETE'
    });

    this.http.delete(environment.server_URL + 'products/' + id, { headers })
      .subscribe(
        (data: any) => {
          console.log(data);
          this.getProducts();
        },
        err => {
          console.log(err);
        });
  }

  postProduct() {
    const dialogRef = this.dialog.open(ProductPostComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result == 'posted') {
        this._snackBar.open('Produkt erstellt!', undefined, {
          duration: 3000,
        });
        this.getProducts();
      }
    });
  }
}
