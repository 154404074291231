<body>
  <div style="color: #553A51; text-align: center;padding-top: 9px; padding-bottom: 7px; opacity: 0.55;"
    (click)="toCategories()">Zu
    Kategorien</div>
  <mat-grid-list cols="2">
    <mat-grid-tile [@bounceInAnimation] *ngFor="let product of products"
      (@bounceInAnimation.done)="onAnimationEnd($event)">
      <mat-card (click)="navigateToDetails(product._id)" id="container" *ngIf="product.stock!=='disabled'"
        [ngClass]="{'outOfStock': product.stock === 'outOfStock'}">
        <mat-card-title>{{product.productName}}</mat-card-title>
        <mat-card-content>
          <p class="truncate">
            {{product.description}}
          </p>
          <p style="display: flex; align-items: center;">
            <mat-icon *ngFor="let star of stars; let i = index"
              [ngClass]="{'active': i &lt; (setStars(product) | number:'1.0-0'), 'inactive': i >= (setStars(product) | number:'1.0-0')}"
              inline>star</mat-icon>
            <span style="color: rgb(151, 151, 151);font-size: 12px;">&nbsp;{{setStars(product) |
              number:'1.1-1'}}&nbsp;({{product.reviews.length}})</span>
          </p>
        </mat-card-content>
        <mat-card-actions align="end" class="button-border">
          <button mat-icon-button *ngIf="product.stock=='inStock'">
            <mat-icon (click)="addProduct(product); $event.stopPropagation()" class="add-button"
              *ngIf="!product.isLoading; else spinnerTemplate">add_outline</mat-icon>
            <ng-template #spinnerTemplate>
              <span class="spinner-container" (click)="$event.stopPropagation()">
                <mat-spinner diameter="23" color="accent"></mat-spinner>
              </span>
            </ng-template>
          </button>
        </mat-card-actions>
        <ng-container *ngIf="product.stock === 'outOfStock'">
          <div class="outOfStockMessage">Nicht Vorrätig</div>
        </ng-container>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</body>