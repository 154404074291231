import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { trigger, keyframes, style, animate, transition, state, AnimationEvent } from '@angular/animations';
import { ChartService } from '../chart.service';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css'],
  animations: [
    trigger('bounceInAnimation', [
      transition(':enter', [
        animate('0.5s', keyframes([
          style({ opacity: 0, transform: 'scale(.3)', offset: 0 }),
          style({ opacity: 1, transform: 'scale(1.05)', offset: 0.5 }),
          style({ transform: 'scale(.9)', offset: 0.7 }),
          style({ transform: 'scale(1)', offset: 1 })
        ]))
      ])
    ]),
    trigger('starAnimation', [
      state('inactive', style({
        color: 'rgb(151, 151, 151)',
        transform: 'scale(1)'
      })),
      state('active', style({
        color: 'gold',
        transform: 'scale(1.2)'
      })),
      transition('inactive <=> active', animate('200ms ease-in-out'))
    ]),
  ],
})
export class CardsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private chartService: ChartService, private http: HttpClient) { }
  products = [];
  stars: number[] = [1, 2, 3, 4, 5];

  ngOnInit(): void {
    this.getProducts(this.route.snapshot.queryParamMap.get('categorie_id'));
  }

  navigateToDetails(product_id): void {
    const navigationExtras = {
      queryParams: {
        product_id: product_id,
        animate: true // Indicate that animation is required
      }
    };
    this.router.navigate(['/cards-detail'], navigationExtras);
  }

  getProducts(categorie_id) {
    this.http.get(environment.server_URL + "products/category/" + categorie_id).subscribe((data: any) => {
      this.products = data.map(item => ({
        ...item,
        isLoading: false
      }));
      console.log(this.products);
    },
      (error) => {                              //Error callback
        console.error('error caught in component: ' + error)
        alert('Categorien konnten nicht geladen werden! Eine Bestellung ist leider nicht möglich.')
        //throw error;   //You can also throw the error to a global error handler
      })
  }

  setStars(product) {
    let all_rates = 0;
    for (let i = 0; i < product.reviews.length; i++) {
      all_rates += product.reviews[i].rate;
    }
    return all_rates = all_rates / product.reviews.length;
  }

  addProduct(product) {
    product.isLoading = true;
    // Simulate a 0.5 second delay
    setTimeout(() => {
      let product_to_order = {
        product_id: product._id,
        productName: product.productName,
        productImage: product.productImage
      }

      this.chartService.addProductToList(product_to_order);
      console.log(this.chartService.products);

      product.isLoading = false;
    }, 750);
  }

  toCategories() {
    this.router.navigate(['/categories']);
  }

  onAnimationEnd(event: AnimationEvent): void {
    // Check if all products have finished their animations
  }
} 
