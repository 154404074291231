import { Component, OnInit } from '@angular/core';
import { ChartService } from '../chart.service';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css']
})
export class BasketComponent implements OnInit {
  serverURL = environment.server_URL;

  constructor(public chartService: ChartService, private http: HttpClient, private dialogRef: MatDialogRef<BasketComponent>) { }

  ngOnInit(): void {
    console.log(this.chartService.products)
  }

  removeProduct(i, product) {
    product.isLoading = true;
    // Simulate a 0.5 second delay
    setTimeout(() => {
      this.chartService.removeFromProduct(i);
    }, 500);
  }
  postOrder() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,PUT,DELETE'
    });

    let order_string = {
      tableNumber: this.chartService.table,
      products: this.chartService.products,
    }
    console.log(order_string);
    this.http.post(environment.server_URL + "orders/", order_string, { headers })
      .subscribe((data: any) => {
        console.log(data);
        this.dialogRef.close('ordered');
        // this.dialog.open(SuccessOrderComponent, {
        //   data: { html_text: "Ihre Bestellung war erfolgreich!", },
        // });
      },
        (error) => {                              //Error callback
          console.error('error caught in component: ' + error)
          alert('Bestellung war NICHT erfolgreich! Bitte versuchen Sie es später nocheinmal.')
          //throw error;   //You can also throw the error to a global error handler
        })
  }
}
