<body>
  <mat-card class="example-card" [@bounceInAnimation]>
    <mat-card-content>
      <!-- <mat-form-field style="width: 100%;">
        <mat-label>Vorname</mat-label>
        <input matInput #firstname>
      </mat-form-field>
      <mat-form-field style="width: 100%;">
        <mat-label>Nachname</mat-label>
        <input matInput #lastname>
      </mat-form-field> -->
      <mat-form-field style="width: 100%;">
        <mat-label>Tisch</mat-label>
        <input matInput #table type="number">
      </mat-form-field>
    </mat-card-content>
    <mat-card-actions>
      <!-- <button mat-button style="background-color: #E1D894; width: 100%; color: #553A51;"
        (click)="addUser(firstname.value, lastname.value)"
        [disabled]="disable_login(firstname.value, lastname.value)">Bestätigen</button> -->
      <button mat-button style="background-color: #E1D894; width: 100%; color: #553A51;" (click)="addTable(table.value)"
        [disabled]="disable_table(table.value)">Bestätigen</button>
    </mat-card-actions>
  </mat-card>
</body>