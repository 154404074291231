<body>
  <div class="search-filter-container">
    <mat-form-field class="search-field">
      <mat-label>Suche</mat-label>
      <input matInput (input)="applySearchFilter($event.target.value)">
    </mat-form-field>

    <div class="add-field">
      <button mat-flat-button class="post-button" (click)="postCategorie()" style="width: 100%;">Hinzufügen</button>
    </div>
  </div>

  <mat-card *ngFor="let categorie of filteredCategories" class="categorie-card">
    <div class="header-content">
      <span class="header-left">
        <mat-card-title>{{ categorie.categorieName }}</mat-card-title>
      </span>
      <div class="header-right">
        <button mat-icon-button>
          <mat-icon (click)="deleteCategorie(categorie._id)">delete</mat-icon>
        </button>
      </div>
    </div>
    <mat-card-content>
      <div class="categorie-container">
        <img mat-card-md-image [src]="serverURL + categorie.categorieImage" class="thumb" width="50">
        {{ categorie.description }}
      </div>
    </mat-card-content>
  </mat-card>
</body>