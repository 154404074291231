import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CardsDetailComponent } from './cards-detail/cards-detail.component';
import { CardsComponent } from './cards/cards.component';
import { LoginComponent } from './login/login.component';
import { CategoriesComponent } from './categories/categories.component';
import { RecipesComponent } from './recipes/recipes.component';
import { OrdersComponent } from './orders/orders.component';
import { ProductPostComponent } from './product-post/product-post.component';
import { AllProductsComponent } from './all-products/all-products.component';
import { AllCategoriesComponent } from './all-categories/all-categories.component';

const routes: Routes = [
  { path: '', component: LoginComponent, data: { hideToolbar: false } },
  { path: 'categories', component: CategoriesComponent, data: { hideToolbar: false } },
  { path: 'cards', component: CardsComponent, data: { hideToolbar: false } },
  { path: 'recipes', component: RecipesComponent, data: { hideToolbar: false } },
  { path: 'orders', component: OrdersComponent, data: { hideToolbar: false } },
  // { path: 'product-post', component: ProductPostComponent, data: { hideToolbar: false } },
  { path: 'all-products', component: AllProductsComponent, data: { hideToolbar: false } },
  { path: 'all-categories', component: AllCategoriesComponent, data: { hideToolbar: false } },
  { path: 'cards-detail', component: CardsDetailComponent, data: { hideToolbar: true } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
