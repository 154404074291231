import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-categorie-post',
  templateUrl: './categorie-post.component.html',
  styleUrls: ['./categorie-post.component.css']
})
export class CategoriePostComponent implements OnInit {

  categorieName: string = "";
  description: string = "";
  categorieFormData: FormData = new FormData();;
  fileName: string = "";
  serverURL = environment.server_URL;

  constructor(private http: HttpClient, private dialogRef: MatDialogRef<CategoriePostComponent>) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    let headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,OPTIONS,PUT,DELETE'
    });

    this.categorieFormData.append('categorieName', this.categorieName);
    this.categorieFormData.append('description', this.description);

    this.http.post(this.serverURL + 'categories/', this.categorieFormData)
      .subscribe((data: any) => {
        console.log(data);
        this.clearAll();
        this.dialogRef.close('posted');
        // this.dialog.open(SuccessOrderComponent, {
        //   data: { html_text: "Ihre Bestellung war erfolgreich!", },
        // });
      },
        (error) => {                              //Error callback
          console.error('error caught in component: ' + error);
          alert('Produkt konnte nicht hinzugefügt werden.');
          this.clearAll();
        })
  }

  disable_post() {
    return !this.categorieName || !this.description || !this.fileName
  }

  clearAll() {
    this.categorieName = "";
    this.description = "";
    this.fileName = "";
    this.categorieFormData = new FormData();
  }

  onFileSelected(event) {

    const file: File = event.target.files[0];

    if (file) {
      this.fileName = file.name;

      this.categorieFormData.append('categorieImage', file);
    }
  }

}
